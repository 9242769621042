import { useGeneralStore } from '../store';
import { useEffect, useState } from 'react';
import { CURRENT, GENERAL } from 'src/constants';
import { ICurrentData, Locales } from '../types';
import { useQuery } from '@tanstack/react-query';
import { getRequest } from '../tools/request';
import { useAddGlobalSpinner } from '../hooks/useAddGlobalSpinner';
import { v4 as uuidv4 } from 'uuid';

export const useGetCurrent = () => {
    const queryKey = [GENERAL, CURRENT];
    const { updateGeneralStore, generalStore } = useGeneralStore();
    const { services, token, messageList } = generalStore;
    const [statusError, setStatusError] = useState<number | null>(null);
    const select = (response: ICurrentData) => response;
    const queryFn = () =>
        getRequest({
            url: services['core/structure-service'].CURRENT_URL,
            token: token,
            onError: (error) => {
                setStatusError(error.response ? error.response?.status : null)
                updateGeneralStore({ messageList: [
                        ...messageList,
                        {
                            text: error?.message,
                            title: error?.name,
                            uuid: uuidv4()
                        }
                    ] });
            }
        });
    const enabled = Boolean(token);

    const { data, isFetching, error } = useQuery<ICurrentData, unknown, ICurrentData, any>({
        queryKey,
        queryFn,
        select,
        enabled,
    });

    useEffect(() => {
        if (statusError && statusError === 404) {
            localStorage.removeItem('xtoken');
            window.location.reload();
            return;
        }

        if (!data) {
            return;
        }


        if (Object.values(data).length > 0) {
            updateGeneralStore({ currentData: data });
        }
    }, [data, updateGeneralStore, statusError]);

    useAddGlobalSpinner({ isFetching, text: 'Loading currents' });
};
