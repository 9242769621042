import { IConnectorDataByConnectorId } from '../interfaces';
import { IConnectorAstral, IConnectorCashierModule } from '../../../types/connectors';


const connectorDataByTypeConnector: IConnectorDataByConnectorId = {
    alsep: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'alsep',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        login: '',
        password: '',
        place_address: '',

    },
    astral: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'astral',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        api_key: '',
        organization: '',
        place_id: '',

    },
    beeline: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'beeline',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        email: '',
        password: '',
        inn: '',
        kkts: '',

    },
    demo: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'demo',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        source_tenant_id: 0,
        coefficient: ''
    },
    dodo: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'dodo',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        country: '',
        city: '',
        place_id: ''
    },
    dsm: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'dsm',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        login: '',
        password: '',
        project: '',
        cm_id: '',
        port: 0,
        active_hash: false
    },
    evotor: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'evotor',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        token: '',
        place_id: ''
    },
    firstofd: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'firstofd',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        login: '',
        password: '',
        organisation: '',
        place_id: ''
    },
    firstofd_v2: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'firstofd_v2',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        inn: '',
        token: '',
        place_id: ''
    },
    iiko: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'iiko',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        login: '',
        password: '',
        service_host: '',
        place_id: ''
    },
    kontur: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'kontur',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        login: '',
        password: '',
        integrator_id: '',
        inn: '',
        place_id: ''
    },
    kzsoapover1c: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'kzsoapover1c',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        service_host: '',
        login: '',
        password: '',
        place_id: ''
    },
    moysklad: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'moysklad',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        login: '',
        password: '',
        place_id: ''
    },
    nifi: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'nifi',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        port:0,
        config: {}
    },
    ofdru: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'ofdru',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        login: '',
        password: '',
        inn: '',
    },
    ofdru_v2: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'ofdru_v2',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        token: '',
        kkts: ''
    },
    platforma: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'platforma',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        login: '',
        password: '',
        inn: '',
        user_id: '',
        place_id: '',
    },
    posterpos: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'posterpos',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        token: '',
        place_id: ''
    },
    progfisc: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'progfisc',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        kkms: ''
    },
    prosklad: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'prosklad',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        login: '',
        password: '',
        place_id: ''
    },
    push_api: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'push_api',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        token: '',
        jq_path: ''
    },
    pysimple: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'pysimple',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        alias: '',
        config: {}
    },
    renta: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'renta',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        login: '',
        password: '',
        inn_tenant: '',
        inn_project: '',
        kpp_project: '',
        place_id: '',
        address: ''
    },
    taxcom: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'taxcom',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        login: '',
        password: '',
        integrator_id: '',
        agreement_number: '',
        place_id: '',
    },
    tenzor: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'tenzor',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        login: '',
        password: '',
        place_id: '',
        inn: '',
        integrator_id: '',
        kktSalesPoint: ''
    },
    tis24: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'tis24',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        login: '',
        password: '',
        place_id: '',
    },
    webkassa: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'webkassa',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        login: '',
        password: '',
        place_id: '',
    },
    yarus: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'yarus',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        token: '',
        place_id: ''
    },
    yarusarenda: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'yarusarenda',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
        /**Кастомные данные **/
        login: '',
        password: '',
        inn: '',
        name: '',
        place_id: ''
    },
    cashier_module: {
        is_active: true,
        date_from: null,
        date_to: null,
        connector_type: 'cashier_module',
        legal_entity: null,
        tenant_inn: null,
        tenant_kpp: null,
        tenant_ogrn: null,
        tenant_bik: null,
        tenant_agreement_number: null,
        tenant_address: null,
        comment: null,
        load_time_utc: [],
        tenant: 0,
        proxy: null,
        tenant_id: 0,
    }

}

export default connectorDataByTypeConnector