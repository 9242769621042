import { CONNECTOR_PARAMS, GENERAL, LIST_POINTS_SALE } from 'src/constants';
import { useGeneralStore } from '../store';
import { IListPointsOfSale, IParamsUseGetPoint } from '../components/Connectors/interfaces';
import { UrlsByServices } from '../types';
import { getRequest } from '../tools/request';
import { useQuery } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';

export const useGetPointsOfSale = ({
                                        dateFrom,
                                        dateTo,
                                        limit,
                                        offset,
                                        PLId,
                                        tenantId,
                                       connectorId
                                   }: IParamsUseGetPoint) => {
    const queryKey = [GENERAL, CONNECTOR_PARAMS, connectorId, LIST_POINTS_SALE];
    const { updateGeneralStore, getGeneralStore } = useGeneralStore();
    const { currentData, token, services, messageList } = getGeneralStore();

    const select = (response: IListPointsOfSale) => response;

    const getUrl = (_services: UrlsByServices): string => {
        return `${_services['fsf/metric-storage'].POINTS_OF_SALE}?date_from=${dateFrom}&date_to=${dateTo}&limit=${limit}&offset=${offset}&project_location_id=${PLId}&tenant_id=${tenantId}&connector_ids=${connectorId}`;
    }
    const queryFn = () =>
        getRequest({
            url: getUrl(services),
            token: token,
            onError: (error) => {
                updateGeneralStore({ messageList: [
                        ...messageList,
                        {
                            text: error?.message,
                            title: error?.name,
                            uuid: uuidv4()
                        }
                    ] });
            }
        });
    const enabled = Boolean(token) && Boolean(tenantId) && Boolean(connectorId);

    const { data, isFetching, error, refetch } = useQuery<IListPointsOfSale, unknown, IListPointsOfSale, any>({
        queryKey,
        queryFn,
        select,
        enabled,
    });



    return { isFetching,  refetch, data};
}