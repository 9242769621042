import { useGeneralStore } from '../store';
import { UrlsByServices } from '../types';
import { IBodyTaskCreator, IParamsCancelTask, IResTaskCreator } from '../components/Chapters/Tasks/interfaces';
import { postRequest } from '../tools/request';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useAddGlobalSpinner } from '../hooks';
import { queryClient } from 'src';
import { CONNECTOR_TASKS, CONNECTOR_TASKS_GET, GENERAL, TASKS_STORE } from '../constants';
import { useTaskStore } from '../store/useTaskStore';

export const usePostTaskCanceler = (keyName?: string | undefined) => {
    const { getGeneralStore, updateGeneralStore } = useGeneralStore();
    const { updateTasksStore } = useTaskStore();
    const { token, services, messageList } = getGeneralStore();

    const getUrl = (_services: UrlsByServices): string => {
        return `${_services['fsf/metric-storage'].TASK_CANCELER}`;
    };
    const mutationFn = (body: IParamsCancelTask) =>
        postRequest({
            url: getUrl(services),
            token: token,
            data: body,
        });

    const { data, isPending, mutate } = useMutation<IParamsCancelTask, unknown, IParamsCancelTask, any>({
        mutationFn,
        onError: (error) => {
            const myError = error as AxiosError;
            updateGeneralStore({
                messageList: [
                    ...messageList,
                    {
                        text: myError.message,
                        title: myError.name,
                        uuid: uuidv4(),
                    },
                ],
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [TASKS_STORE, CONNECTOR_TASKS] });
            queryClient.invalidateQueries({ queryKey: [TASKS_STORE, CONNECTOR_TASKS_GET] });
        },
    });
    // console.log('>>>>>>>>>>>>>>>>>>>>>>', isPending);
    useAddGlobalSpinner({ isFetching: isPending, text: 'Cancel Task' });
    return { isPending, mutate, data };
};
