import { colorsByNameStatus } from '../Monitoring/settings/colorsByNameStatus';

const enumColors: { [key: string]: string } = {
    '0': colorsByNameStatus.noReceipts,
    '-4': colorsByNameStatus.pending,
    '-3': colorsByNameStatus.savedToBucket,
    '-2': colorsByNameStatus.success,
    '>0': colorsByNameStatus.success,
    '<-4': colorsByNameStatus.error,
};

const getColor = (status?: number): string => {
    if (status === undefined) {
        return '';
    }
    if (enumColors[String(status)]) {
        return enumColors[String(status)];
    } else {
        if (status > 0) {
            return enumColors['>0'];
        } else if (status < -4) {
            return enumColors['<-4'];
        }
    }
    return '';
};

export default getColor;
