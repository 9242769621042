import { memo, useRef, useMemo, useCallback, useEffect, useState } from 'react';
import { Button, Tooltip } from '@fluentui/react-components';
import { ArrowClockwise28Filled, Dismiss28Filled } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';
import { UseMutateFunction } from '@tanstack/react-query';
import { FocusTable, Selection, ColumnOption, TableContext, Primitive } from '@focus/front-lib';
import {
    transformFields,
    filterKeys,
    makeTransformFieldsByPairs,
    makeBackTransformFieldsByPairs,
    camelToSnakeCase,
} from '@focus/front-lib'; // src/components/FocusTable

import { KFACTORHEIGHTTABLE_MONITORING } from 'src/constants';

import { IBodyTaskCreator, IResTaskCreator, IParamsCancelTask, IStatuses } from '../../interfaces';
import { useTaskStore } from '../../../../../store/useTaskStore';
import getColor from '../../../Tasks/tools/getColor';
import getStatus from '../../../Tasks/tools/getStatuses';
import {
    BodyMonitoringTasksRowSC,
    CellWrapper,
    DivWrapperChartAndTitleSC,
    DivWrapperChartsContentSC,
} from '../../styles';

import { filteringByConnectorTypes } from '../../tools/filteringConnectorTypes';
import { ITableListTasksItem } from '../../../../../types';
import filteringListByTaskStatuses from '../../tools/filteringListByTaskStatuses';
import { usePostTaskCanceler } from '../../../../../api/usePostTaskCanceler';
import { usePostTaskCreate } from '../../../../../api/usePostTaskCreate';
import { getPercentage } from '../../tools/getPercentage';
import { getChunkArrays } from '../../tools/getChunkArrays';
import { CHUNK_SIZE } from '../../constants';
import { createBody } from '../../tools/createBody';
import { arrayCancelStatuses } from '../settings/arrayCancelStatuses';

import { ChartBox } from './ChartBox';
import { ControlButtons } from './ControlButtons';
import { Title } from './Title';
import styled from 'styled-components';

type ExternalContext = {
    mutateCanceler: UseMutateFunction<IParamsCancelTask, unknown, IParamsCancelTask, any>;
    mutateReload: UseMutateFunction<IResTaskCreator, unknown, IBodyTaskCreator, any>;
};

const COLUMN_SHOWN_ID_PAIRS: Array<[string, string]> = [
    ['showAttempt', 'attempt'],
    ['showDelayedTo', 'delayedTo'],
    ['showDuration', 'duration'],
    ['showIsolatedWorkers', 'isIsolated'],
    ['showPLId', 'PLId'],
    ['showPriority', 'priority'],
    ['showStartedAt', 'startedAt'],
    ['showTaskSource', 'taskSource'],
];

const REQUIRED_FIELDS = ['dateFrom', 'dateTo', 'PLId', 'tenantId', 'connectorId', 'connectorType'];
const FIELDS_PAIRS = [['project_location_ids', 'PLId']] as Array<[string, string]>;

export const ButtonsWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

export const columnsOptions: ColumnOption<string, ITableListTasksItem, ExternalContext>[] = [
    {
        id: 'taskControl',
        label: 'Task control',
        hasSort: false,
        valueAccessor: (row: ITableListTasksItem) => row,
        columnRenderer: (
            valueOrAccessor: ITableListTasksItem,
            context: TableContext<string, ITableListTasksItem, ExternalContext>,
        ) => {
            if (typeof valueOrAccessor !== 'object') {
                return null;
            }
            const rowFromAccessor = valueOrAccessor as ITableListTasksItem;

            const { t, externalContext } = context;
            const { mutateCanceler, mutateReload } = externalContext || {};

            const onClickDismiss = (e: React.MouseEvent) => {
                e.stopPropagation();
                if (!rowFromAccessor) {
                    return;
                }
                const obj: IParamsCancelTask = {
                    date_from: rowFromAccessor.dateFrom!,
                    date_to: rowFromAccessor.dateFrom!,
                    project_location_ids: [rowFromAccessor.PLId!],
                    tenant_ids: [rowFromAccessor.tenantId!],
                    connector_ids: [rowFromAccessor.connectorId!],
                    connector_types: [rowFromAccessor.connectorType!],
                };

                // const rowFromAccessorEdited = {
                //     ...rowFromAccessor,
                //     PLId: [rowFromAccessor.PLId],
                // }
                // const transformedTask = transformFields<IParamsCancelTask>(
                //     rowFromAccessorEdited,
                //     filterKeys({ include: REQUIRED_FIELDS }),
                //     makeBackTransformFieldsByPairs(FIELDS_PAIRS),
                //     camelToSnakeCase,
                // );

                mutateCanceler?.(obj);
            };
            const onClickReload = (e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                if (!rowFromAccessor) {
                    return;
                }
                const obj: IBodyTaskCreator = {
                    date_from: rowFromAccessor.dateFrom!,
                    date_to: rowFromAccessor.dateFrom!,
                    project_location_ids: [rowFromAccessor.PLId!],
                    tenant_ids: [rowFromAccessor.tenantId!],
                    connector_ids: [rowFromAccessor.connectorId!],
                    connector_types: [rowFromAccessor.connectorType!],
                };
                // const rowFromAccessorEdited = {
                //     ...rowFromAccessor,
                //     PLId: [rowFromAccessor.PLId],
                // }
                // const transformedTask = transformFields<IBodyTaskCreator>(
                //     rowFromAccessorEdited,
                //     filterKeys({ include: REQUIRED_FIELDS }),
                //     makeBackTransformFieldsByPairs(FIELDS_PAIRS),
                //     camelToSnakeCase,
                // );

                mutateReload?.(obj);
            };

            const isShowCancelButton = arrayCancelStatuses?.find(
                (item) => item.statusCode === rowFromAccessor.statusCode,
            );

            return (
                <ButtonsWrapper>
                    {isShowCancelButton && (
                        <Tooltip content={t('Stop')} relationship="label">
                            <Button
                                style={{ padding: 0 }}
                                appearance="subtle"
                                onClick={onClickDismiss}
                                icon={<Dismiss28Filled color={'red'} />}
                            />
                        </Tooltip>
                    )}
                    <Tooltip content={t('Reload')} relationship="label">
                        <Button
                            style={{ padding: 0 }}
                            appearance="subtle"
                            onClick={onClickReload}
                            icon={<ArrowClockwise28Filled />}
                        />
                    </Tooltip>
                </ButtonsWrapper>
            );
        },
    },
    {
        id: 'id',
        label: 'Task id',
        // visible: false,
    },
    {
        id: 'connectorId',
        label: 'Connector id',
        // visible: false
    },
    {
        id: 'tenantId',
        label: 'Tenant id',
        // visible: false
    },

    { id: 'tenantName', label: 'Tenant name' },
    { id: 'PLId', label: 'Project location id', columnStyle: { minWidth: 150 }, visible: false },

    { id: 'PLName', label: 'Location' },
    { id: 'taskSource', label: 'Task source', visible: false },

    // { id: 'posName', minWidth: 200, title: 'Pos Details' },
    { id: 'connectorType', label: 'Connector type' },
    { id: 'dateFrom', label: 'Date', columnStyle: { minWidth: 90 } },
    // { id: 'dateTo', label: 'Date to' },
    {
        id: 'status',
        label: 'Status',
        columnStyle: { position: 'relative', padding: '0' },
        valueAccessor: (row: ITableListTasksItem) => row,
        columnRenderer: (valueOrAccessor: ITableListTasksItem | Primitive) => {
            if (typeof valueOrAccessor !== 'object') {
                return null;
            }
            const rowFromAccessor = valueOrAccessor as ITableListTasksItem;

            const bgColor = getColor(rowFromAccessor.statusCode);
            const status = getStatus(rowFromAccessor.statusCode ?? -9);
            return (
                <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
                    <CellWrapper $bgColor={bgColor}>
                        <Tooltip content={status} relationship="label">
                            <div>{status}</div>
                        </Tooltip>
                    </CellWrapper>
                </div>
            );
        },
        valueFormatter: (valueOrAccessor: ITableListTasksItem) => {
           return valueOrAccessor.status || "";
        }
    },
    { id: 'billsNumber', label: 'Bills number' },

    { id: 'isIsolated', label: 'Isolated workers', visible: false },
    { id: 'priority', label: 'Priority', visible: false },
    { id: 'attempt', label: 'Attempt', visible: false },

    { id: 'createdAt', label: 'Created at', columnStyle: { minWidth: 150 } },
    { id: 'startedAt', label: 'Started at', visible: false },
    { id: 'delayedTo', label: 'Delayed to', visible: false },
    { id: 'duration', label: 'Duration', visible: false },

    { id: 'error', label: 'Error', columnStyle: { minWidth: 150, maxWidth: 300, wordWrap: 'break-word' } },
];

export const BodyMonitoring = memo(() => {
    const [tableHeight, setTableHeight] = useState<number>(0);
    const [tableDataListTemp, setTableDataListTemp] = useState<ITableListTasksItem[]>([]);
    const [selectedCells, setSelectedCells] = useState<Selection | null>(null);
    const { getTasksStore, updateTasksStore } = useTaskStore();
    const { t } = useTranslation();
    const [statuses, setStatuses] = useState<IStatuses>({
        error: {
            number: 0,
            name: 'Error',
            count: 0,
        },
        pending: {
            number: 0,
            name: 'Pending',
            count: 0,
        },
        success: {
            number: 0,
            name: 'Success',
            count: 0,
        },
        noReceipts: {
            number: 0,
            name: 'No receipts',
            count: 0,
        },
    });
    const {
        selectedTypesConnector,
        tableListTasks,
        listTaskStatusesFilter,
        selectedTasks,
        listSettingsTableMonitoring,
    } = getTasksStore();

    const { mutate: mutateCanceler, isPending: isPendingCanceler } = usePostTaskCanceler('statusReloadAndCanceler');
    const { mutate: mutateReload, isPending: isPendingCreate } = usePostTaskCreate('statusReloadAndCanceler');

    const tableContextRef = useRef<TableContext<string, ITableListTasksItem, ExternalContext> | null>(null);

    useEffect(() => {
        if (!tableContextRef.current) {
            return;
        }

        const { changeExternalContext } = tableContextRef.current;
        changeExternalContext?.({
            mutateCanceler,
            mutateReload,
        });
    }, [mutateCanceler, mutateReload]);

    useEffect(() => {
        const columnsVisibility = listSettingsTableMonitoring.reduce(
            (acc, { id, checked }) => ({ ...acc, [id]: checked }),
            {},
        );
        const transformedVisibility = transformFields<Record<string, boolean>>(
            columnsVisibility,
            makeTransformFieldsByPairs(COLUMN_SHOWN_ID_PAIRS),
        );
        tableContextRef.current?.changeColumnVisibility?.(transformedVisibility);
    }, [listSettingsTableMonitoring]);

    useEffect(() => {
        let temp: ITableListTasksItem[] = filteringByConnectorTypes<ITableListTasksItem>({
            keys: ['connectorType'],
            list: selectedTypesConnector,
            filterList: tableListTasks,
            typeCompare: 'strict',
        });

        if (selectedTypesConnector.length === 0) {
            temp = tableListTasks;
        }

        temp = filteringListByTaskStatuses<ITableListTasksItem>({
            list: temp,
            listStatuses: listTaskStatusesFilter,
        });
        updateTasksStore({
            selectedTasks: [],
        });
        setSelectedCells(null);
        setTableDataListTemp(temp);
    }, [selectedTypesConnector, updateTasksStore, tableListTasks, listTaskStatusesFilter]);

    useEffect(() => {
        if (tableDataListTemp.length > 0) {
            const filteredError = tableDataListTemp.filter((item) => (item.statusCode ? item.statusCode < -4 : false));
            const filteredPending = tableDataListTemp.filter((item) =>
                item.statusCode ? item.statusCode === -4 : false,
            );
            const filteredSuccess = tableDataListTemp.filter((item) =>
                item.statusCode ? item.statusCode === -2 || item.statusCode > 0 : false,
            );
            const filteredWaiting = tableDataListTemp.filter((item) =>
                item.statusCode !== undefined ? item.statusCode === 0 : false,
            );

            const error = getPercentage(filteredError.length, tableDataListTemp.length);
            const pending = getPercentage(filteredPending.length, tableDataListTemp.length);
            const success = getPercentage(filteredSuccess.length, tableDataListTemp.length);
            const waiting = getPercentage(filteredWaiting.length, tableDataListTemp.length);
            setStatuses({
                error: {
                    number: error,
                    name: 'Error',
                    count: filteredError.length,
                },
                pending: {
                    number: pending,
                    name: 'Pending',
                    count: filteredPending.length,
                },
                success: {
                    number: success,
                    name: 'Success',
                    count: filteredSuccess.length,
                },
                noReceipts: {
                    number: waiting,
                    name: 'No receipts',
                    count: filteredWaiting.length,
                },
            });
        }
    }, [tableDataListTemp]);

    const getHeaderHeight = (): number => {
        return document.getElementById('HeaderMonitoring')
            ? document.getElementById('HeaderMonitoring')!.getBoundingClientRect().height
            : 0;
    };

    const onSizeWindow = useCallback((e: any) => {
        setTableHeight(e.target.innerHeight - (KFACTORHEIGHTTABLE_MONITORING + getHeaderHeight()));
    }, []);

    const onResizeAfterEffect = useCallback(() => {
        setTableHeight(window.innerHeight - (KFACTORHEIGHTTABLE_MONITORING + getHeaderHeight()));
    }, []);

    useEffect(() => {
        window.onresize = onSizeWindow;
        onResizeAfterEffect();
    }, []);

    const onSelectionChange = useCallback(
        (selection: Selection | null, selectedRows: ITableListTasksItem[]) => {
            updateTasksStore({
                selectedTasks: selectedRows,
            });
            setSelectedCells(selection);
        },
        [updateTasksStore],
    );

    const createDataReq = () => {
        const chunkArray = getChunkArrays(selectedTasks, CHUNK_SIZE);
        let arrayBodies: IParamsCancelTask[] = [];
        chunkArray.forEach((item, i) => {
            arrayBodies.push(createBody(item) as IParamsCancelTask);
        });
        return arrayBodies;
    };

    const onCancel = () => {
        const arrayBodies: IParamsCancelTask[] = createDataReq();
        arrayBodies.forEach((item) => {
            mutateCanceler(item);
        });
    };

    const onRestart = () => {
        const arrayBodies: IBodyTaskCreator[] = createDataReq() as IBodyTaskCreator[];
        arrayBodies.forEach((item) => {
            mutateReload(item);
        });
    };

    const tableWrapperStyle = useMemo(
        () => ({
            marginTop: '30px',
            maxHeight: `${tableHeight}px`,
        }),
        [tableHeight],
    );

    const TableComponent = useMemo(() => {
        return (
            // <FocusTable<string, ITableListTasksItem, ExternalContext, ITableListTasksItem>
            <FocusTable
                componentId="BodyMonitoring"
                columnsOptions={columnsOptions}
                wrapperStyle={tableWrapperStyle}
                selectedCells={selectedCells}
                onSelectionChange={onSelectionChange}
                contextRef={tableContextRef}
                values={tableDataListTemp}
            />
        );
    }, [onSelectionChange, tableWrapperStyle, selectedCells, tableDataListTemp]);

    return (
        <BodyMonitoringTasksRowSC>
            <DivWrapperChartAndTitleSC>
                <Title s={t('Total tasks')} iTableListTasksItems={tableDataListTemp} s1={t('Date')} s2={t('Created')} />
                <DivWrapperChartsContentSC>
                    <ChartBox statuses={statuses} />
                    <div></div>
                    <ControlButtons
                        disabledButton={selectedTasks.length === 0}
                        onCancel={onCancel}
                        onRestart={onRestart}
                        selectedTasks={selectedTasks}
                        isPendingCancel={isPendingCanceler}
                        isPendingCreate={isPendingCreate}
                    />
                </DivWrapperChartsContentSC>
            </DivWrapperChartAndTitleSC>

            {TableComponent}
        </BodyMonitoringTasksRowSC>
    );
});
