import { ColumnOption, CHECKED_IDENTITY_COLUMN, Identity, defaultCheckboxRenderer } from '@focus/front-lib';

import { IConnectorItemListForTable } from '../../interfaces';
import { emptyArray } from 'src/tools';

export type ExternalContext = { activeRows: number[] };

export const columnsOptions: ColumnOption<string, IConnectorItemListForTable, ExternalContext>[] = [
    {
        ...CHECKED_IDENTITY_COLUMN,
        headerRenderer: (_label, column: ColumnOption<string, Identity, ExternalContext>, tableContext) => {
            const {
                checkedRows,
                changeCheckedRows,
                data,
                externalContext: { activeRows },
            } = tableContext;

            const filteredData = data.filter(({ id }) => activeRows?.includes(Number(id)));

            const handleCheckClick = () => {
                const hasUnchecked = filteredData.find(({ id }) => checkedRows?.[id] !== true);

                const newCheckedRows = data.reduce((acc, { id }) => {
                    if (!(activeRows ?? []).includes(Number(id))) {
                        return { ...acc };
                    }
                    return { ...acc, [id]: !!hasUnchecked };
                }, {} as Record<string, boolean>);
                changeCheckedRows?.(newCheckedRows);
            };

            const isChecked = !filteredData.find(({ id }) => checkedRows?.[id] !== true);
            return defaultCheckboxRenderer({
                key: 'headerCheck',
                isChecked,
                onChange: handleCheckClick,
            });
        },
    } as ColumnOption<string, IConnectorItemListForTable, ExternalContext>,
    { id: 'id', label: 'POS ID', visible: false },
    { id: 'tenantId', label: 'Tenant id' },
    { id: 'tenantName', label: 'Tenant name' },
    { id: 'locationId', label: 'Location id' },
    // { id: 'posName', minWidth: 200, title: 'Pos Details' },
    { id: 'locationName', label: 'Location name' },
    { id: 'connectorType', label: 'Connector type' },
    { id: 'connectorId', label: 'Connector id' },
    { id: 'dateFrom', label: 'Date from' },
    { id: 'dateTo', label: 'Date to' },
    // { id: 'accumulatorId', style: { minWidth: 200 }, label: 'Fiscal device POS ID', align: 'right', visible: false },
    // { id: 'dateFrom', style: { minWidth: 120 }, sortOrder: 'asc' },
    // { id: 'dateTo', style: { minWidth: 120 }, sortOrder: 'asc' },
    // { id: 'firstDate', label: 'Date of first receipt', hasSort: false, hasFilter: false },
    // { id: 'firstTime', label: 'Time of first receipt', hasSort: false, hasFilter: false, visible: false },
    // { id: 'lastDate', label: 'Date of last receipt', hasSort: false, hasFilter: false },
    // { id: 'lastTime', label: 'Time of last receipt', hasSort: false, hasFilter: false, visible: false },
    // { id: 'sales', label: 'Sales (Inc. VAT)', hasSort: false, hasFilter: false },
    // { id: 'receiptsCount', label: 'Num of Receipts', hasSort: false, hasFilter: false },
];
