import { CONNECTOR_TYPES, TASKS_STORE, TENANTS } from 'src/constants';
import { useGeneralStore } from '../store';
import { useTaskStore } from '../store/useTaskStore';
import { IResponseTenant } from '../types/tasks';
import { TConnectorTypes, UrlsByServices } from '../types';
import { getRequest } from '../tools/request';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useAddGlobalSpinner } from '../hooks';

export const useGetConnectorTypes = () => {
    const queryKey = [TASKS_STORE, CONNECTOR_TYPES];
    const { updateGeneralStore, getGeneralStore } = useGeneralStore();
    const {
        updateTasksStore,
        getTasksStore
    } = useTaskStore();
    const { currentData, token, services, messageList } = getGeneralStore();
    const { id } = currentData;
    const select = (response: TConnectorTypes) => response;



    const getUrl = (_services: UrlsByServices): string => {
        return `${_services['fsf/metric-storage'].MANAGE_CONNECTORS}connector-types/`
    }
    const queryFn = () =>
        getRequest({
            url: getUrl(services),
            token: token,
            onError: (error) => {
                updateGeneralStore({ messageList: [
                        ...messageList,
                        {
                            text: error?.message,
                            title: error?.name,
                            uuid: uuidv4()
                        }
                    ] });
            }
        });

    const enabled = Boolean(token)

    const { data, isFetching, error, refetch } = useQuery<TConnectorTypes, unknown, TConnectorTypes, any>({
        queryKey,
        queryFn,
        select,
        enabled,
    });

    useEffect(() => {

        if (!data) {
            return;
        }

        updateTasksStore({
            connectorTypes: data
        });

    }, [data, updateGeneralStore]);
    useAddGlobalSpinner({ isFetching, text: 'Loading tenants' });
    // return { isFetching, refetch };
}