import { useGeneralStore } from '../store';
import {
    IConnector,
    IDataBeforeRequest,
    IDataConnectors,
    IDataReqStructure,
    IDataTenants,
    ITenant,
    ITenant2Place,

} from '../types';
import { getRequest } from '../tools/request';
import {
    OmitKeyof,
    QueriesPlaceholderDataFunction,
    useQueries,
    UseQueryOptions,
} from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import {
    formatObjUseQueriesConnectors,
    formatObjUseQueriesStructure,
    formatObjUseQueriesTenants,
} from '../tools/formatObjUseQueries';
import { Interval, DateTime } from 'luxon';
import processNullToDate from '../tools/processNullToDate';
import { v4 as uuidv4 } from 'uuid';
import { AxiosError } from 'axios';



interface TQueries {
    queries: [
        OmitKeyof<UseQueryOptions, 'placeholderData'> & { placeholderData?: QueriesPlaceholderDataFunction<never> },
    ];
}

export const useGetTenantsAndConnectors = (plId: string) => {
    const { updateGeneralStore, getGeneralStore } = useGeneralStore();
    const { currentData, token, services, messageList } = getGeneralStore();
    const { id } = currentData;
    const [arrayObjDataBeforeReq, setArrayObjDataBeforeReq] = useState<IDataBeforeRequest[]>([]);

    useEffect(() => {
        if (Object.keys(services).length > 0 && Boolean(plId)) {
            const objDataBeforeRequest: IDataBeforeRequest[] = [
                {
                    // url: getUrl(services['fsf/metric-storage'].TENANTS_URL),
                    url: services['fsf/metric-storage'].TENANTS_URL,
                    type: 'tenants',
                    func: getRequest,
                },
                {
                    url: services['fsf/metric-storage'].CONNECTORS_URL,
                    type: 'connectors',
                    func: getRequest,
                },
                {
                    url: `${services['core/structure-service'].LOCATIONS_URL}${plId}/core/relations_tenant2place/`,
                    type: 'tenant2place',
                    func: getRequest,
                },
            ];
            setArrayObjDataBeforeReq(objDataBeforeRequest);
        }

    }, [services, plId]);


    const dataPostReq: IDataReqStructure = {
        auth: {
            xtoken: token,
        },
        queries: [
            {
                key: 'core/relations_tenant2place',
                item: `pl_structure/pl${plId}/core/relations_tenant2place.json`,
            },
        ],
    };
    const enabled = Boolean(token) && Boolean(id) && Boolean(plId) && Boolean(arrayObjDataBeforeReq);

    const onError = (error: AxiosError) => {
        updateGeneralStore({ messageList: [
                ...messageList,
                {
                    text: error?.message,
                    title: error?.name,
                    uuid: uuidv4()
                }
            ] });
    }


    const results = useQueries<TQueries[]>({
        queries: arrayObjDataBeforeReq.map((item, i) => {
            switch (item.type) {
                case 'connectors':
                    return formatObjUseQueriesConnectors(item, token, enabled, Number(plId), 0, 10000, onError);
                case 'tenants':
                    return formatObjUseQueriesTenants(item, token, enabled, Number(plId), 0, 10000, onError);
                case 'tenant2place':
                    return formatObjUseQueriesStructure(item, token, enabled, Number(plId), onError);
                default:
                    return formatObjUseQueriesConnectors(item, token, enabled,  Number(plId), 0, 10000, onError);
            }
        }),
    });



    const allSuccess = results.every((item) => item.isSuccess);
    const isFetching = results.some((item) => item.isFetching);
    useEffect(() => {
        if (!allSuccess) {
            return;
        }
        const objData: {
            tenants: ITenant[];
            connectors: IConnector[];
            tenant2place: ITenant2Place[];
            tenantsCTG: IDataTenants | null;
            connectorsCTG: IDataConnectors | null;
            tenant2placeCTG: ITenant2Place[];
        } = {
            tenants: [],
            connectors: [],
            tenant2place: [],
            tenantsCTG: null,
            connectorsCTG: null,
            tenant2placeCTG: [],
        };

        results.forEach((item, i) => {
            if (item.data) {
                Object.keys(item.data).forEach((key: string) => {

                    // TODO надо поправить типы
                    // @ts-ignore


                    // @ts-ignore
                    objData[key] = key !== 'tenant2place' ? item.data[key].results : item.data[key];
                    // @ts-ignore
                    objData[`${key}CTG`] = item.data[key];
                });
            }
        });
        objData.tenants = objData.tenants.map((item, i) => {

            const found = objData.tenant2place.find((element) => {
                return item.id === element.tenant_id;
            });

            let count: number = 0;
            let countNotActive: number = 0
            objData.connectors.forEach((itemConnector, i) => {

                if (itemConnector.is_active) {
                    if (item.id === itemConnector.tenant_id) {
                        count += 1;
                    }
                }
                else {
                    if (item.id === itemConnector.tenant_id) {
                        countNotActive += 1;
                    }
                }

            });
            return {
                ...item,
                is_actual: Boolean(found),
                connectors_count: count,
                connectorsCountNotActive: countNotActive,
            };
        });

        objData.connectors = objData.connectors.map((itemConnector, i) => {
            let status = false
            if (!itemConnector.date_from && !itemConnector.date_to) {
                status = true;
            }
            else {
                const mainInterval = Interval.fromISO(`${processNullToDate(itemConnector.date_from, 'dateFrom')}/${processNullToDate(itemConnector.date_to, 'dateTo')}`);
                const compareInterval = Interval.fromISO(`${DateTime.now().toFormat('yyyy-MM-dd')}/${DateTime.now().plus({month: 1}).toFormat('yyyy-MM-dd')}`);


                if (DateTime.fromISO(processNullToDate(itemConnector.date_from, 'dateFrom')).toMillis()
                    >
                    DateTime.fromISO(processNullToDate(itemConnector.date_to, 'dateTo')).toMillis()) {
                    status = false
                }
                else {
                    if (compareInterval.intersection(mainInterval)) {
                        status = true;
                    }
                }


            }

            return {
                ...itemConnector,
                isActual: status
            }
        });

        updateGeneralStore({ ...objData });
    }, [JSON.stringify(results), allSuccess]);

    return isFetching;
};
