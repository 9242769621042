import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, PopoverProps, useId } from '@fluentui/react-components';
import { useStyles } from '../../../../Connectors/styles';
import { DateTime } from 'luxon';
import { DivWrapperButtomSC, HeaderCreateTasksSC } from '../../styles';
import { IBodyTaskCreator, IParamsOnChangeSelection, TOption } from '../../interfaces';
import { useGeneralStore } from '../../../../../store';
import { useTaskStore } from '../../../../../store/useTaskStore';
import { useGetTenants } from '../../../../../api/useGetTenants';
import { useGetConnectors } from '../../../../../api/useGetConnectors';
import { usePostTaskCreate } from '../../../../../api/usePostTaskCreate';
import initialListSettingsTable from '../settings/listSettingsTable';
import { DAYS, MILLISECONDS_IM_ONE_MONTH } from '../../constants';
import { LocationsSelect } from '../../CommonComponents/LocationsSelect';
import { TenantsFilter } from '../../CommonComponents/TenantsFilter';
import { ConnectorTypesSelect } from '../../CommonComponents/ConnectorTypesSelect';
import { TableSettingsSelect } from '../../CommonComponents/TableSettingsSelect';
import { DatePickerFrom } from '../../CommonComponents/DatePickerFrom';
import { DatePickerTo } from '../../CommonComponents/DatePickerTo';

export const HeaderCreateTasks = () => {
    const [openLocationSelect, setOpenLocationSelect] = React.useState<boolean>(false);
    const [openConnectorsType, setOpenConnectorsType] = React.useState<boolean>(false);
    const [openTableSettingsSelect, setOpenTableSettingsSelect] = React.useState<boolean>(false);
    const { t } = useTranslation();
    const inputId = useId('input');
    const inputId2 = useId('input2');
    const styles = useStyles();
    const { getGeneralStore } = useGeneralStore();
    const { getTasksStore, updateTasksStore } = useTaskStore();
    const { allLocations } = getGeneralStore();
    const {
        selectedLocations,
        connectorTypes,
        selectedTypesConnector,
        tenantsFilterValue,
        selectedDateFrom,
        selectedDateTo,
        selectedConnectors,
        listSettingsTable,
    } = getTasksStore();
    const { mutate } = usePostTaskCreate();

    // Вычисляемые переменные
    const label = selectedLocations.map((item) => item.name).join(', ');
    const idsList = selectedLocations.map((item) => item.id);

    const labelTypes = selectedTypesConnector.map((item) => item.connector_type).join(', ');
    const idsListTypes = selectedTypesConnector.map((item) => item.connector_type);

    const optionsLocations: TOption[] = allLocations.map((item) => ({
        id: item.id,
        name: item.name,
    }));

    const optionsConnectorType: TOption[] = connectorTypes.map((item) => ({
        id: item.connector_type,
        name: item.connector_type,
    }));

    const listIdSelectedTableSettings = listSettingsTable.filter((item) => item.checked).map((item) => item.id);
    const labelAllSelectedSettings = listSettingsTable
        .filter((item) => item.checked)
        .map((item) => item.name)
        .join(', ');

    // Хуки для получения данных
    const { refetch } = useGetTenants(
        {
            limit: 10000,
            offset: 0,
            plIds: idsList,
        },
        !openLocationSelect,
    );

    const { refetch: refetch2 } = useGetConnectors(
        {
            limit: 10000,
            offset: 0,
            plIds: idsList,
        },
        !openLocationSelect,
    );

    // Инициализация настроек таблицы
    useEffect(() => {
        if (listSettingsTable.length === 0) {
            updateTasksStore({
                listSettingsTable: initialListSettingsTable,
            });
        }
    }, []);

    // Обновление данных при изменении выбранных локаций
    useEffect(() => {
        // if (selectedLocations.length > 0 && !openLocationSelect) {
        //     refetch();
        //     refetch2();
        // }
    }, [selectedLocations, openLocationSelect]);

    // Обработчики открытия/закрытия поповеров
    const handleOpenChangeLocations: PopoverProps['onOpenChange'] = (e, data) => {
        setOpenLocationSelect(data.open || false);
    };

    const handleOpenChangeConnectorsTypes: PopoverProps['onOpenChange'] = (e, data) => {
        setOpenConnectorsType(data.open || false);
    };

    const handleOpenChangeTableSettings: PopoverProps['onOpenChange'] = (e, data) => {
        setOpenTableSettingsSelect(data.open || false);
    };

    // Обработчики изменения дат
    const onChangeDateFrom = (date: Date | null | undefined) => {
        if (date) {
            const selectedToDateMillis = DateTime.fromISO(selectedDateTo || '').toMillis();
            const selectedFromDateMillis = DateTime.fromJSDate(date).toMillis();
            if (
                selectedToDateMillis - selectedFromDateMillis >= MILLISECONDS_IM_ONE_MONTH ||
                selectedToDateMillis - selectedFromDateMillis < 0
            ) {
                let tempDate = new Date(date);
                tempDate.setDate(tempDate.getDate() + DAYS);
                if (DateTime.fromJSDate(tempDate).toMillis() > DateTime.now().toMillis()) {
                    tempDate = new Date();
                }
                updateTasksStore({
                    selectedDateTo: DateTime.fromJSDate(tempDate).toFormat('yyyy-MM-dd'),
                });
            }
            updateTasksStore({
                selectedDateFrom: DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'),
            });
        }
    };

    const onChangeDateTo = (date: Date | null | undefined) => {
        if (date) {
            const selectedFromDateMillis = DateTime.fromISO(selectedDateFrom || '').toMillis();
            const selectedToDateMillis = DateTime.fromJSDate(date).toMillis();
            if (
                selectedToDateMillis - selectedFromDateMillis >= MILLISECONDS_IM_ONE_MONTH ||
                selectedToDateMillis - selectedFromDateMillis < 0
            ) {
                let tempDate = new Date(date);
                tempDate.setDate(tempDate.getDate() - DAYS);
                updateTasksStore({
                    selectedDateFrom: DateTime.fromJSDate(tempDate).toFormat('yyyy-MM-dd'),
                });
            }
            updateTasksStore({
                selectedDateTo: DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'),
            });
        }
    };

    const formatDate = (date?: Date) => {
        return date ? DateTime.fromJSDate(date).toFormat('yyyy-MM-dd') : '';
    };

    // Обработчики выбора опций
    const selectOption = (params: IParamsOnChangeSelection) => {
        updateTasksStore({
            ...params,
        });
    };

    const filterTableList = (value: string) => {
        updateTasksStore({
            tenantsFilterValue: value,
        });
    };

    const getMaxDateForDateFromCalendar = () => {
        const date = new Date();
        date.setDate(date.getDate());
        return date;
    };

    const getMaxDateForDateToCalendar = () => {
        return new Date();
    };

    const createTask = () => {
        const obj: IBodyTaskCreator = {
            date_from: selectedDateFrom,
            date_to: selectedDateTo,
            project_location_ids: selectedLocations.map((item) => item.id),
            tenant_ids: [],
            connector_ids: selectedConnectors.map((item) => item.connectorId),
            connector_types: [],
            connector_types_isolated: [],
        };
        mutate(obj);
    };

    const getIsDisabled = () => {
        return selectedConnectors.length === 0;
    };

    return (
        <HeaderCreateTasksSC>
            <LocationsSelect
                labelG={t('Locations')}
                label={label}
                open={openLocationSelect}
                onOpenChange={handleOpenChangeLocations}
                onChangeSelection={selectOption}
                listOptions={optionsLocations}
                selectedIds={idsList}
                keyName={'selectedLocations'}
                selectedLocations={selectedLocations}
                allLocations={allLocations}
            />
            <TenantsFilter label={t('Tenants filter')} handleChange={filterTableList} value={tenantsFilterValue} />
            <ConnectorTypesSelect
                labelG={t('Connectors type')}
                label={labelTypes}
                open={openConnectorsType}
                onOpenChange={handleOpenChangeConnectorsTypes}
                onChangeSelection={selectOption}
                listOptions={optionsConnectorType}
                selectedIds={idsListTypes}
                keyName={'selectedTypesConnector'}
                selectedTypesConnector={selectedTypesConnector}
                connectorTypes={connectorTypes}
            />
            <TableSettingsSelect
                labelG={t('Table settings')}
                label={labelAllSelectedSettings}
                open={openTableSettingsSelect}
                onOpenChange={handleOpenChangeTableSettings}
                onChangeSelection={selectOption}
                listOptions={listSettingsTable}
                selectedIds={listIdSelectedTableSettings}
                keyName={'listSettingsTable'}
            />
            <div></div>
            <DatePickerFrom
                htmlFor={inputId}
                label={t('Select a date from')}
                styles={styles}
                onSelectDate={onChangeDateFrom}
                formatDate={formatDate}
                selectedDateFrom={selectedDateFrom}
                maxDateForDateFromCalendar={getMaxDateForDateFromCalendar()}
            />
            <DatePickerTo
                htmlFor={inputId2}
                label={t('Select a date to')}
                styles={styles}
                onSelectDate={onChangeDateTo}
                formatDate={formatDate}
                selectedDateTo={selectedDateTo}
                maxDateForDateToCalendar={getMaxDateForDateToCalendar()}
            />
            <DivWrapperButtomSC>
                <Button appearance="primary" onClick={createTask} disabled={getIsDisabled()}>
                    {t('Create tasks')}
                </Button>
            </DivWrapperButtomSC>
        </HeaderCreateTasksSC>
    );
};
