import {projectTheme} from "../../../theme";
import {CustomTableRowSC} from "../../Projects/styles";
import {TableCell, TableCellLayout} from "@fluentui/react-components";
import { FC} from "react";

import formatText from '../../../tools/formatText';
import { IPropsRenderRow } from '../../Table/interfaces';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export const RenderRow: FC<IPropsRenderRow> = ({index, style, data}) => {
    const { t } = useTranslation();

    const {item, selected, appearance, onClick, onKeyDown} = data[index];
    const {colorBrandBackground2, colorNeutralForegroundStaticInverted} = projectTheme;

    const bg = index % 2 !== 0 ? colorBrandBackground2 : colorNeutralForegroundStaticInverted

    
    const listComponents = Object.keys(item).map((_item, i) => {

        if (_item === 'locationId') {
            return (
                <TableCell
                    key={`item table row${i}`}
                >
                    <TableCellLayout>
                        <Link to={`/configuration/connectors/project/${item.projectsId.label}/pl/${item[_item].label}`}>
                            {`${t('Jump to location')} ${item[_item].label}`}
                        </Link>


                    </TableCellLayout>

                </TableCell>
            )
        }
        else if (_item === 'projectsId') {
            return (
                <TableCell
                    key={`item table row${i}`}
                >
                    <TableCellLayout>
                        <Link to={`/configuration/connectors/project/${item[_item].label}`}>
                            {`${t('Jump to project')} ${item[_item].label}`}
                        </Link>


                    </TableCellLayout>

                </TableCell>
            )
        }
        return (
            <TableCell
                key={`item table row${i}`}
            >
                <TableCellLayout>

                    {formatText(item[_item].label, 30)}
                </TableCellLayout>

            </TableCell>
        )
    })

    return (
        <CustomTableRowSC
            key={`item.file.label${index}`}
            aria-rowindex={index + 2}
            style={style}
            onKeyDown={onKeyDown}
            onClick={onClick}
            appearance={appearance}
            $bg={bg}
        >
            {listComponents}
        </CustomTableRowSC>
    )
}