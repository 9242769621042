import { useEffect } from 'react';

import { commonTheme } from './commonTheme';

const toCssName = (camelCaseName: string) => {
    return camelCaseName.replace(/([A-Z])/g, '-$1').toLowerCase();
};

export const useAssignVars = () => {
    useEffect(() => {
        const colors = Object.entries(commonTheme.colors)
        colors.forEach((entry) => {
            const [colorName, colorValue] = entry;
            document.documentElement.style.setProperty(`--color-${toCssName(colorName)}`, colorValue);
        });
    }, []);
};
