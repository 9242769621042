import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { Identity } from '@focus/front-lib';
import { emptyArray, toString } from '@focus/front-lib';
import { FocusTable, TableConfigChanges, TableContext } from '@focus/front-lib';
import { useResizeObserver } from '@focus/front-lib';

import { BodyCreateTasksRowSC } from '../../styles';
import { IConnectorItemListForTable } from '../../interfaces';
import { useTaskStore } from '../../../../../store/useTaskStore';
import { columnsOptions, ExternalContext } from '../settings/columnForTable';
import { useGeneralStore } from '../../../../../store';
import { KFACTORHEIGHTTABLE } from '../../../../../constants';
import getIsIntersection from '../../../../../tools/getIsIntersection';
import filteringTableList from '../../../../../tools/filteringTableList';

import { projectTheme } from '../../../../../theme';

import 'react-datasheet/lib/react-datasheet.css';

// import { BodyCreateTasksRowSC, CustomTdSC, DivActionCellSC } from '../../styles';
// import React, { useCallback, useEffect, useState } from 'react';
// import { ColumnOptions, FocusTable, TableRange } from '../../../../FocusTable';
// import { IConnectorItemListForTable, TListSettings } from '../../interfaces';
// import { useTaskStore } from '../../../../../store/useTaskStore';
// import { columnsOptions } from '../settings/columnForTable';
// import { useGeneralStore } from '../../../../../store';
// import 'react-datasheet/lib/react-datasheet.css';
// import { KFACTORHEIGHTTABLE } from '../../../../../constants';
import { compare } from '../../../../../tools/compare';
// import getIsIntersection from '../../../../../tools/getIsIntersection';
// import { DateTime } from 'luxon';
// import filteringTableList from '../../../../../tools/filteringTableList';
import { filteringByConnectorTypes } from '../../tools/filteringConnectorTypes';
// import { CustomTrSC } from '../../../../FocusTable/styles';
import { Button, Tooltip } from '@fluentui/react-components';
import { ArrowClockwise28Filled, Dismiss28Filled } from '@fluentui/react-icons';
import { REACT_APP_REF } from 'src/constants/index';
import { use } from 'i18next';
// import { projectTheme } from '../../../../../theme';

export const BodyCreateTasks = () => {
    const [tableHeight, setTableHeight] = useState<number>(0);
    const { generalStore } = useGeneralStore();
    const { getTasksStore, updateTasksStore } = useTaskStore();
    const [tableDataListTemp, setTableDataListTemp] = useState<IConnectorItemListForTable[]>([]);
    const [changesCheck, setChangesCheck] = useState<TableConfigChanges | null>(null);

    const tableContextRef = useRef<TableContext<string, IConnectorItemListForTable, ExternalContext> | null>(null);

    const {
        connectors,
        tenantDataByTenantId,
        selectedDateFrom,
        selectedDateTo,
        // connectors,
        // tenantDataByTenantId,
        tableDataList,
        tenantsFilterValue,
        selectedTypesConnector,
        selectedConnectors,
        statusSelectedAll,
        selectedListRowTable,
        // selectedDateFrom,
        // selectedDateTo,
        connectorsListIntersectionDate,
        listSettingsTable,
    } = getTasksStore();
    const { pLDataByPLId } = generalStore;

    const tableData = useMemo(() => {
        if (
            !tenantDataByTenantId ||
            !pLDataByPLId ||
            !connectors?.length ||
            !Object.keys(tenantDataByTenantId).length ||
            !Object.keys(pLDataByPLId).length
        ) {
            return emptyArray;
        }

        return connectors
            .filter(({ id }) => id !== undefined)
            .map<IConnectorItemListForTable>((connector) => ({
                id: toString(connector.id),
                tenantName: tenantDataByTenantId[connector.tenant_id]?.name,
                tenantId: connector.tenant_id,
                locationId: tenantDataByTenantId[connector.tenant_id]?.project_location,
                locationName: pLDataByPLId[tenantDataByTenantId[connector.tenant_id]?.project_location]?.name,
                connectorType: connector.connector_type,
                connectorId: connector.id!,
                dateFrom: connector.date_from,
                dateTo: connector.date_to,
            }));
        // .filter((item) => {
        //     return getIsIntersection({
        //         dateToMain: selectedDateTo,
        //         dateFromMain: selectedDateFrom,
        //         dateToCompare: item.dateTo,
        //         dateFromCompare: item.dateFrom,
        //     });
        // });
    }, [tenantDataByTenantId, connectors, pLDataByPLId, selectedDateTo, selectedDateFrom]);

    // useEffect(() => {
    //     let selectedListRowTableTemp: number[] = [];
    //     tableDataListTemp.forEach((item, i) => {
    //         selectedConnectors.forEach((item2) => {
    //             if (item.id === item2.id) {
    //                 selectedListRowTableTemp.push(i);
    //             }
    //         });
    //     });
    //
    //     updateTasksStore({
    //         // statusSelectedAll:
    //         //     tableDataListTemp.length > 0
    //         //         ? compare(selectedConnectors, tableDataListTemp)
    //         //         : false,
    //         selectedListRowTable: selectedListRowTableTemp,
    //     });
    // }, [selectedConnectors, tableDataListTemp]);

    useEffect(() => {
        let date = new Date();
        date.setDate(date.getDate() - 31);
        updateTasksStore({
            selectedDateFrom: DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'),
            selectedDateTo: DateTime.now().toFormat('yyyy-MM-dd'),
        });
    }, []);

    useEffect(() => {
        if (changesCheck) {
            const checked = changesCheck?.checkedRows ?? {};
            const selectedConnectors = tableDataListTemp.filter(({ connectorId }) => {
                return Boolean(checked[connectorId]);
            });
            updateTasksStore({ selectedConnectors });
        }
    }, [changesCheck, tableDataListTemp]);

    useEffect(() => {
        let tempArray: number[] = [];
        let arraySelectedConnectorTemp: IConnectorItemListForTable[] = [];
        let arraySelectedConnectorRowTemp: number[] = [];
        tableDataListTemp.forEach((item, i) => {
            if (
                getIsIntersection({
                    dateToMain: selectedDateTo,
                    dateFromMain: selectedDateFrom,
                    dateToCompare: item.dateTo,
                    dateFromCompare: item.dateForm,
                })
            ) {
                tempArray.push(item.connectorId);
                if (selectedListRowTable.includes(i)) {
                    arraySelectedConnectorRowTemp.push(i);
                    arraySelectedConnectorTemp.push(item);
                }
            }
        });

        updateTasksStore({
            connectorsListIntersectionDate: tempArray,
            selectedConnectors: arraySelectedConnectorTemp,
            selectedListRowTable: arraySelectedConnectorRowTemp,
        });
    }, [selectedDateFrom, selectedDateTo, tableDataListTemp]);

    useEffect(() => {
        let listTableTemp: IConnectorItemListForTable[] = tableData;
        listTableTemp = filteringByConnectorTypes<IConnectorItemListForTable>({
            keys: ['connectorType'],
            list: selectedTypesConnector,
            filterList: listTableTemp,
        });
        if (selectedTypesConnector.length === 0) {
            listTableTemp = tableData;
        }
        listSettingsTable.forEach((item) => {
            if (item.id === 'active' && item.checked) {
                listTableTemp = tableDataListTemp.filter((item2, i) =>
                    connectorsListIntersectionDate.includes(item2.connectorId),
                );
            }
        });

        listTableTemp = filteringTableList<IConnectorItemListForTable>({
            keys: ['tenantId', 'tenantName'],
            list: listTableTemp,
            valueFilter: tenantsFilterValue,
        });

        setTimeout(() => {
            setTableDataListTemp(listTableTemp);
        }, 100);
    }, [tenantsFilterValue, tableData, listSettingsTable, connectorsListIntersectionDate, selectedTypesConnector]);

    useResizeObserver({
        ref: REACT_APP_REF,
        onResize: (size) => {
            setTableHeight((size.height ?? 2 * KFACTORHEIGHTTABLE) - KFACTORHEIGHTTABLE);
        },
    });

    //     listTableTemp = filteringTableList<IConnectorItemListForTable>({
    //         keys: ['tenantId', 'tenantName'],
    //         list: listTableTemp,
    //         valueFilter: tenantsFilterValue,
    //     })

    // const selectAllRow = (status: boolean, list:  IConnectorItemListForTable[]) => {
    //     const filteredArray = filterIntersection(list);
    //     updateTasksStore({
    //         selectedConnectors: status ? filteredArray : [],
    //         statusSelectedAll: status,
    //     });
    // };

    useEffect(() => {
        tableContextRef?.current?.changeExternalContext?.({ activeRows: connectorsListIntersectionDate });
    }, [connectorsListIntersectionDate]);

    useEffect(() => {
        const wrongChecked = Object.keys(changesCheck?.checkedRows ?? {}).filter(
            (id) => !connectorsListIntersectionDate?.includes(Number(id)),
        );
        if (wrongChecked.length) {
            const checked = changesCheck?.checkedRows ?? {};
            const newChecked = { ...checked };
            wrongChecked.forEach((id) => {
                newChecked[id] = false;
            });

            tableContextRef?.current?.changeCheckedRows?.(newChecked);

            const args: TableConfigChanges = { componentId: 'BodyCreateTasks', checkedRows: newChecked };
            setChangesCheck(args);
        }
    }, [connectorsListIntersectionDate]);

    const getCellStyle = useCallback(
        (
            isHeader: boolean,
            row: Identity<string> | IConnectorItemListForTable,
            context: TableContext<string, IConnectorItemListForTable, ExternalContext>,
        ) => {
            const isEnabledRow = connectorsListIntersectionDate?.includes(Number(row.id));
        
            if (isHeader) {
                return undefined;
            }

            const checked = context?.checkedRows ?? {};
            const isChecked = Boolean(checked[row.id]);
            return {
                ...(isChecked && { backgroundColor: projectTheme.colorBrandBackground2 }),
                ...!isEnabledRow && {
                    pointerEvents: ('none' as const),
                    opacity: '0.5',
                },
            };
        },
        [connectorsListIntersectionDate],
    );

    const onChangeContextConfig = (changes: TableConfigChanges) => {
        setChangesCheck(changes);
    };

    const tableWrapperStyle = useMemo(
        () => ({
            marginTop: '30px',
            maxHeight: `${tableHeight}px`,
        }),
        [tableHeight],
    );

    return (
        <BodyCreateTasksRowSC>
            <FocusTable
                componentId="BodyCreateTasks"
                columnsOptions={columnsOptions}
                wrapperStyle={tableWrapperStyle}
                values={tableDataListTemp}
                contextRef={tableContextRef}
                onChangeContextConfig={onChangeContextConfig}
                cellStyle={getCellStyle}
            />
        </BodyCreateTasksRowSC>
    );
};
