import { CONNECTOR, GENERAL } from 'src/constants';
import { useGeneralStore } from '../store';
import { ILocationsById, TLocations, UrlsByServices } from '../types';
import { getRequest } from '../tools/request';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { TConnectorReq } from '../types/connectors';
import { v4 as uuidv4 } from 'uuid';

export const useGetConnector = (connectorId: number | undefined, connectorType:string) => {
    const queryKey = [GENERAL, CONNECTOR, connectorId, connectorType];
    const { updateGeneralStore, getGeneralStore } = useGeneralStore();
    const { currentData, token, services, messageList } = getGeneralStore();
    const { id } = currentData;
    const select = (response: TConnectorReq) => response;



    const getUrl = (_services: UrlsByServices, _connectorId: number, _connectorType: string): string => {
        return `${_services['fsf/metric-storage'].MANAGE_CONNECTORS}${_connectorType}/${_connectorId}/`
    }
    const queryFn = () =>
        getRequest({
            url: getUrl(services, connectorId ? connectorId : 0, connectorType),
            token: token,
            onError: (error) => {
                updateGeneralStore({ messageList: [
                        ...messageList,
                        {
                            text: error?.message,
                            title: error?.name,
                            uuid: uuidv4()
                        }
                    ] });
            }
        });
    const enabled = Boolean(token) && Boolean(id) && Boolean(connectorId);

    const { data, isFetching, error, refetch } = useQuery<TConnectorReq, unknown, TConnectorReq, any>({
        queryKey,
        queryFn,
        select,
        enabled: false,
    });

    useEffect(() => {

        if (!data) {
            return;
        }

        updateGeneralStore({
            selectedConnectorDetail: data,
            selectedConnectorDetailCTG: data
        });

    }, [data, updateGeneralStore]);
    return { isFetching, refetch };
}