import React, { FC } from 'react';
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { Field, Label, useId } from '@fluentui/react-components';
import { useStyles } from '../../styles';
import { IPropsCalendarBox } from '../../interfaces';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';


const CalendarBox: FC<IPropsCalendarBox> = (props) => {
    const {
        label,
        dateG = '',
        keyName,
        value,
        onChange
    } = props
    const styles = useStyles();
    const inputId = useId("input");
    const { t } = useTranslation();


    const onChangeDate = (date: Date | null | undefined) => {
        if (date) {
            onChange(keyName, DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'))
        }
    }

    const formatDate = (date?: Date | undefined) => {
        if (!date) {
            return ''
        }
        return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
    }



    return (
        <div>
            <Label  htmlFor={inputId}>
                {`${t('Select a ')}${label.toLowerCase()}`}
            </Label>
            <Field id={inputId}>
                {
                    keyName === 'date_to' ?
                        <DatePicker
                            className={styles.control}
                            placeholder={label}
                            onSelectDate={onChangeDate}
                            formatDate={formatDate}
                            minDate={dateG ? new Date(dateG) : undefined}
                            value={value ? new Date(value) : null}
                        /> :
                        <DatePicker
                            className={styles.control}
                            placeholder={label}
                            onSelectDate={onChangeDate}
                            formatDate={formatDate}
                            maxDate={dateG ? new Date(dateG) : undefined}
                            value={value ? new Date(value) : null}
                        />
                }

            </Field>
        </div>
    );
}

export default CalendarBox;