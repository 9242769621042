import { IBodyTaskCreator, IParamsCancelTask } from '../interfaces';
import { ITableListTasksItem } from '../../../../types';
import { getMaxNumber } from './getMaxNumber';
import { getMinNumber } from './getMinNumber';
import { DateTime } from 'luxon';
import { DATE_FORMAT } from '../../../../constants/dateFormat';

interface ITemp {
    project_location_ids: number[];
    tenant_ids: number[];
    connector_ids: number[];
    connector_types: string[];
    date_from: number[];
    date_to: number[];
    task_ids: string[];
}

export const createBody = (list: ITableListTasksItem[]): IParamsCancelTask | IBodyTaskCreator => {
    const temp: ITemp = {
        project_location_ids: [],
        tenant_ids: [],
        connector_ids: [],
        connector_types: [],
        date_from: [],
        date_to: [],
        task_ids: [],
    };

    list.forEach((item) => {
        temp.project_location_ids.push(item.PLId ? item.PLId : 0);
        temp.tenant_ids.push(item.tenantId ? item.tenantId : 0);
        temp.connector_ids.push(item.connectorId ? item.connectorId : 0);
        temp.connector_types.push(item.connectorType ? item.connectorType : '');
        temp.date_from.push(item.dateFrom ? DateTime.fromISO(item.dateFrom).toMillis() : 0);
        temp.date_to.push(item.dateTo ? DateTime.fromISO(item.dateTo).toMillis() : 0);
        temp.task_ids.push(item.id);
    });

    const body: IParamsCancelTask | IBodyTaskCreator = {
        project_location_ids: temp['project_location_ids'],
        tenant_ids: temp['tenant_ids'],
        connector_ids: temp['connector_ids'],
        connector_types: temp['connector_types'],
        date_from: DateTime.fromMillis(getMinNumber(temp.date_from)).toFormat(DATE_FORMAT),
        date_to: DateTime.fromMillis(getMaxNumber(temp.date_to)).toFormat(DATE_FORMAT),
        task_ids: temp['task_ids'],
    };

    return body;
};
