import styled from 'styled-components';

export const ContainerSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: max-content auto;
    grid-template-columns: 100%;
`;

export const HeaderCreateTasksSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(4, max-content) auto repeat(3, max-content);
    grid-template-rows: 100%;
    align-content: space-evenly;
    padding: 10px;
    box-sizing: border-box;
    align-items: end;
    gap: 10px;
`;

export const HeaderMonitoringSC = styled.div`
    display: grid;
    width: 100%;
    height: max-content;

    grid-template-columns: repeat(auto-fit, minmax(215px, max-content));
    grid-template-rows: max-content;
    align-content: space-evenly;
    padding: 10px;
    box-sizing: border-box;
    align-items: end;
    gap: 10px;
`;
export const BodyCreateTasksRowSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
`;

export const DivWrapperInputAndLabelSC = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 20px auto;
`;

export const DivWrapperButtomSC = styled.div`
    display: grid;
    width: max-content;
    height: max-content;
    grid-template-rows: 32px;
    grid-template-columns: max-content;
`;
export const DivWrapperListPopoverScrollSC = styled.div`
    display: grid;
    width: max-content;
    height: max-content;

    grid-template-columns: max-content max-content max-content;
    grid-template-rows: 100%;
`;

export const DivWrapperListItemSC = styled.div`
    display: grid;
    width: max-content;
    height: 200px;
    max-height: 200px;
    overflow: auto;
    grid-auto-rows: 32px;
`;

export const DivWrapperListPopoverSC = styled.div`
    display: grid;
    width: max-content;
    height: max-content;
    grid-template-columns: 100%;
    grid-template-rows: 32px auto;
    gap: 10px;
`;

export const DivBoxSearchSC = styled.div`
    display: grid;
`;

export const WrapperTooltipContentSC = styled.div`
    display: grid;
    width: max-content;
    height: max-content;
    grid-auto-rows: max-content;
`;

export const DivItemContentTooltipSC = styled.div`
    width: max-content;
    height: max-content;
`;

export const BodyMonitoringTasksRowSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    padding: 10px;
    grid-template-rows: 230px auto;
    grid-template-columns: 100%;
    box-sizing: border-box;
`;
export const DivActionHeaderCellSC = styled.div`
    display: grid;
    justify-items: start;
    width: max-content;
`;
export const DivActionCellSC = styled.div`
    display: grid;
    justify-content: center;
    width: 100%;
    grid-template-columns: max-content max-content;
    grid-template-rows: 100%;
`;


export const CustomTdSC = styled.td`
    border: 1px solid var(--color-secondary-dark) !important;

    width: 100px;
`;

export const DivWrapperChartAndTitleSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 100%;
    grid-template-rows: 30px auto;
`;

export const DivWrapperChartSC = styled.div`
    display: grid;
    width: max-content;
    height: 100%;
    grid-auto-columns: 250px;
    grid-template-rows: 100%;
    grid-auto-flow: column;
`;

export const DivItemFilterSC = styled.div`
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    width: 215px;
`;

export const DivWrapperChartsContentSC = styled.div`
    display: grid;
    width: 100%;
    height: max-content;
    grid-template-columns: max-content auto max-content;
    grid-template-rows: max-content;
`;

export const DivWrapperButtonsSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 32px 32px;
    grid-template-columns: 100%;
    align-content: center;
    gap: 5px;
`;

export const DivWrapperModalContentSC = styled.div`
    display: grid;
    width: 600px;
    height: max-content;
    grid-template-columns: 100%;
    grid-template-rows: max-content max-content;
    gap: 10px;
`;

export const DivWrapperModalButtonsSC = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 100%;
    grid-template-columns: 100px;
    justify-content: end;
`

export const SpanTitle3SC = styled.span`
    font-weight: 900;
    color: var(--color-secondary-darker);
`

export const CellWrapper = styled.div<{
    $bgColor?: string | null;
    $bgOpacity?: string | null;
}>`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: ${({ $bgColor }) => $bgColor && $bgColor};
    opacity: ${({ $bgOpacity }) => $bgOpacity && $bgOpacity};
`;

export const DivWrapperTextSC = styled.div`
    width: 100%;
    -webkit-line-clamp: 2; /* Число отображаемых строк */
    display: -webkit-box; /* Включаем флексбоксы */
    -webkit-box-orient: vertical; /* Вертикальная ориентация */
    overflow: hidden;
    //grid-template-columns: 100%;
    //grid-template-rows: 100%;
`;