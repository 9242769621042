import { useGeneralStore } from '../store';
import { IConnectorDependenciesByConnectorType, IProjectsById, TConnectorTypes, UrlsByServices } from '../types';
import { getRequest, postRequest } from '../tools/request';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@tanstack/react-query';
import { CONNECTOR, CONNECTOR_TYPES, GENERAL } from 'src/constants';
import { TConnectorReq } from '../types/connectors';
import { Logo } from '../components/Logo/Logo';
import { useEffect } from 'react';
import { useAddGlobalSpinner } from '../hooks';

export const useGetConnectorDetail = (plId: string, projectId:string) => {
    const { updateGeneralStore, getGeneralStore } = useGeneralStore();
    const { token, services, messageList } = getGeneralStore();
    const queryKey = [GENERAL, CONNECTOR, CONNECTOR_TYPES, plId, projectId];
    const select = (response: TConnectorTypes) => response;

    const getUrl = (_services: UrlsByServices): string => {
        return `${_services['fsf/metric-storage'].MANAGE_CONNECTORS}connector-types/`
    }


    const queryFn = () =>
        getRequest({
            url: getUrl(services),
            token: token,
            onError: (error) => {
                updateGeneralStore({ messageList: [
                        ...messageList,
                        {
                            text: error?.message,
                            title: error?.name,
                            uuid: uuidv4()
                        }
                    ] });
            }
        })

    const enabled = Boolean(token) && Boolean(plId) && Boolean(projectId);
    const { data, isFetching, error, refetch } = useQuery<TConnectorTypes, unknown, TConnectorTypes, any>({
        queryKey,
        queryFn,
        select,
        enabled,
    });

    useEffect(() => {
        if (data) {
            updateGeneralStore({
                connectorTypesCTG: data
            })
            let obj:IConnectorDependenciesByConnectorType  = {}
            data.forEach((item, i) => {
                obj[item.connector_type] = item
            })
            updateGeneralStore({
                connectorDependenciesByConnectorType: obj
            })
        }
    }, [data]);
    useAddGlobalSpinner({ isFetching, text: 'Loading connector types' });

    return { data, isFetching };
}