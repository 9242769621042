import { useQuery } from '@tanstack/react-query';

import { Locales } from 'src/types';

import { getLocales } from './getLocales';
import { GENERAL, LOCALES } from 'src/constants';
import { useGeneralStore } from 'src/store';
import { useEffect } from 'react';
import { useAddGlobalSpinner } from 'src/hooks/useAddGlobalSpinner';

export const useGetLocales = () => {
    const { updateGeneralStore, generalStore } = useGeneralStore();
    const queryKey = [GENERAL, LOCALES];
    const queryFn = () => getLocales();
    const select = (response: Locales & { domain: string }) => response;

    const { data, isFetching } = useQuery<Locales & { domain: string }, unknown, Locales & { domain: string }, any>({
        queryKey,
        queryFn,
        select,
        staleTime: 6 * 3600 * 1000,
    });

    useEffect(() => {
        if (!data) {
            return;
        }

        if (Object.values(data).length > 0) {
            updateGeneralStore({ locales: data });
        }
    }, [data, updateGeneralStore]);

    useAddGlobalSpinner({ isFetching, text: 'Loading locales' });
};
