import {styled} from "styled-components";
import { sizes } from "../../constants/sizes";
import { makeStyles } from '@fluentui/react-components';

export const useStyles = makeStyles({
    wrapper: {
        columnGap: '15px',
        display: 'flex',
        minWidth: 'min-content',
    },
});

export const HeaderWrapperSC = styled.section<{
    $bgColor: string;
    $color?: string;
}>`
    height: ${sizes.HEADER_HEIGHT + 'px'};
    overflow: hidden;
    background-color: ${(p) => p.$bgColor};
    // color: ${(p) => p.$color};
    display: grid;
    grid-template-columns: auto auto auto max-content;
    grid-template-rows: 100%;
`;

export const NavMenuWrapperSC = styled.div`
    width: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
`;

export const ContainerSC = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .location {
        display: flex;
        align-items: center;
    }
`;

export const SettingsButtonWrapperSC = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
    /* width: 100%; */
    box-sizing: border-box;
    padding: 9px 0;
    button {
        background-color: transparent !important;
        /* color: white !important; */
        font-family: inherit !important;
        font-size: inherit !important;
    }
    i {
        /* color: white !important; */
    }
`;