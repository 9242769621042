import {
    IDataBeforeRequest,
    IDataConnectors,
    IDataReqStructure,
    IDataStructureService,
    IDataTenants,
    TLocations,
} from '../types';
import { GENERAL } from 'src/constants';
import { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import { AxiosError } from 'axios';

export const formatObjUseQueriesTenants = (
    data: IDataBeforeRequest,
    token: string,
    enabled: boolean,
    PLId: number,
    offset: number,
    limit: number,
    onError: (error:AxiosError) => void,
): UseQueryOptions<any, Error, any, QueryKey> => {
    const select = (response: IDataTenants) => {
        const newArray = response.results.map((item, i) => {
            return {
                ...item,
                is_actual: false,
                connectors_count: 0,
            };
        });
        return {
            [data.type]: {
                results: newArray,
            },
        };
    };

    return {
        queryKey: [GENERAL, data.type, PLId],
        queryFn: () =>
            data.func({
                url: `${data.url}?project_location_id=${PLId}&offset=${offset}&limit=${limit}`,
                token: token,
                onError: onError
            }),
        enabled,
        select,
    };
};

export const formatObjUseQueriesConnectors = (
    data: IDataBeforeRequest,
    token: string,
    enabled: boolean,
    PLId: number,
    offset: number,
    limit: number,
    onError: (error:AxiosError) => void,
): UseQueryOptions<any, Error, any, QueryKey> => {
    const select = (response: IDataConnectors) => ({
        [data.type]: response,
    });

    return {
        queryKey: [GENERAL, data.type, PLId],
        queryFn: () =>
            data.func({
                url: `${data.url}?project_location_id=${PLId}&offset=${offset}&limit=${limit}`,
                token: token,
                onError: onError
            }),
        enabled,
        select,
    };
};

export const formatObjUseQueriesStructure = (
    data: IDataBeforeRequest,
    token: string,
    enabled: boolean,
    PLId: number,
    onError: (error:AxiosError) => void,
): UseQueryOptions<any, Error, any, QueryKey> => {
    const select = (response: IDataStructureService) => {
        return {
            [data.type]: response,
        }
    };
    return {
        queryKey: [GENERAL, data.type, PLId],
        queryFn: () =>
            data.func({
                url: `${data.url}`,
                token: token,
                onError: onError
                // data: dataReq,
            }),
        enabled,
        select,
    };
};
