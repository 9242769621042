import {
    ButtonWithSpinnerSC,
    DivSpinnerBox2SC, DivWrapperButton2SC,
    DivWrapperInputsBaseSC,
    DivWrapperInputsCustomAndButtonSC, DivWrapperInputsCustomSC,
} from '../styles';
import React, { FC, useEffect, useState } from 'react';
import { InputText } from './customComponents/InputText';
import { InputCheckBox } from './customComponents/InputCheckBox';
import CalendarBox from './customComponents/CalendarBox';
import CheckboxList from './customComponents/CheckboxList';
import { IArgUpdateDatesFunc, IPropsBaseInputFirstLine } from '../interfaces';
import { Spinner } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { compare } from '../../../tools/compare';

export const BaseInputsFirstLine: FC<IPropsBaseInputFirstLine> = (props) => {
    const {
        connectorDetail,
        inputListBase,
        onChange,
        isPending,
        updateDates,
        type = 'create',
        connectorDetailCTG
    } = props

    const [disabled, setDisabled] = useState<boolean>(false);

    useEffect(() => {
        if (connectorDetail && connectorDetailCTG) {
            const isDisabled = compare(
                {
                    id: connectorDetail.id ? connectorDetail.id : 0,
                    date_from: connectorDetail.date_from,
                    date_to: connectorDetail.date_to,
                    load_time_utc: connectorDetail.load_time_utc,
                    connector_type: connectorDetail.connector_type
                },
                {
                    id: connectorDetailCTG.id ? connectorDetailCTG.id : 0,
                    date_from: connectorDetailCTG.date_from,
                    date_to: connectorDetailCTG.date_to,
                    load_time_utc: connectorDetailCTG.load_time_utc,
                    connector_type: connectorDetailCTG.connector_type
                }
            )
            setDisabled(isDisabled)
            // console.log('>>>>>>>>>>>>>>>>>>>>>>', isDisabled);
        }

    }, [connectorDetail, connectorDetailCTG]);


    const { t } = useTranslation();

    const onClick = () => {
        if (connectorDetail) {
            const obj:IArgUpdateDatesFunc = {
                id: connectorDetail.id ? connectorDetail.id : 0,
                date_from: connectorDetail.date_from,
                date_to: connectorDetail.date_to,
                load_time_utc: connectorDetail.load_time_utc,
                connector_type: connectorDetail.connector_type
            }
            if (updateDates) {
                updateDates(obj);
            }

        }

    }


    // TODO разобраться с типом
    return (
        <DivWrapperInputsCustomAndButtonSC>
            <DivWrapperInputsCustomSC>
                {
                    connectorDetail ? inputListBase.map((item, i) => {

                        if (item.type === 'text') {

                            return(
                                <InputText
                                    key={`sfgerwasfg${i}`}
                                    type={'text'}
                                    // @ts-ignore
                                    value={connectorDetail[item.keyName]}
                                    label={item.label}
                                    keyName={item.keyName}
                                    onChange={onChange}
                                />
                            )
                        }
                        else if (item.type === 'checkbox') {
                            return(
                                <InputCheckBox
                                    key={`sfgerwasfg${i}`}

                                    // @ts-ignore
                                    checked={connectorDetail[item.keyName]}
                                    label={item.label}
                                    keyName={item.keyName}
                                    onChange={onChange}
                                />
                            )
                        }
                        else if (item.type === 'number') {
                            return(
                                <InputText
                                    key={`sfgerwasfg${i}`}
                                    type={'number'}
                                    // @ts-ignore
                                    value={connectorDetail[item.keyName]}
                                    label={item.label}
                                    keyName={item.keyName}
                                    onChange={onChange}
                                />
                            )
                        }
                        else if (item.type === 'timesList') {
                            return(
                                <CheckboxList
                                    key={`sfgerwasfg${i}`}
                                    // @ts-ignore
                                    values={connectorDetail[item.keyName]}
                                    label={item.label}
                                    keyName={item.keyName}
                                    onChange={onChange}
                                    connectorDetail={connectorDetail}
                                />
                            )
                        }
                        else if (item.type === 'date') {
                            return(
                                <CalendarBox
                                    key={`sfgerwasfg${i}`}
                                    label={item.label}
                                    dateG={item.keyName === 'date_to' ? connectorDetail.date_from ? connectorDetail.date_from
                                        : '' : connectorDetail.date_to ? connectorDetail.date_to : ''}

                                    // @ts-ignore
                                    value={connectorDetail[item.keyName]}
                                    keyName={item.keyName}
                                    onChange={onChange}
                                />
                            )
                        }
                    }) : null
                }
            </DivWrapperInputsCustomSC>
            {
                type === 'create' ? null :
                    <DivWrapperButton2SC>
                        <div></div>
                        <ButtonWithSpinnerSC
                            onClick={onClick}
                            disabled={isPending || disabled}
                            appearance="primary"
                            icon={
                                isPending ? (
                                    <DivSpinnerBox2SC>
                                        <Spinner size={'extra-tiny'} />
                                    </DivSpinnerBox2SC>
                                ) : null
                            }
                        >
                            {t('Send Dates')}
                        </ButtonWithSpinnerSC>
                    </DivWrapperButton2SC>
            }

        </DivWrapperInputsCustomAndButtonSC>

    );
};