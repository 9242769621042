import { IConnectorItemListForTable } from '../components/Chapters/Tasks/interfaces';
import { TTableListTasks } from '../types';

interface Args<T> {
    keys: string[];
    list: T[];
    valueFilter: string;
    typeCompare?: 'strict' | 'low';
}

const filteringTableList = <T>(params: Args<T>): T[] => {
    const { keys, list, valueFilter, typeCompare = 'low' } = params;

    if (typeCompare === 'strict') {
        return list.filter(
            (item) => {
                let status: boolean = false
                keys.forEach((item2) => {
                    status = status || String(item[item2 as keyof T]) === String(valueFilter)
                })
                if(keys.length === 0) {
                    status = true
                }
                return status
                // String(item[keys[0] as keyof T]) === String(valueFilter) ||
                // String(item[keys[1] as keyof T]) === String(valueFilter);
            },
        );
    } else {
        return list.filter(
            (item) => {
                let status: boolean = false
                keys.forEach((item2) => {
                    // console.log('rerqwerqqwe', String(item[item2 as keyof T]).toLowerCase().includes(valueFilter.toLowerCase()));
                    status = status || String(item[item2 as keyof T]).toLowerCase().includes(valueFilter.toLowerCase())
                })

                if(keys.length === 0) {
                    status = true
                }

                return status
                // String(item[keys[0] as keyof T]) === String(valueFilter) ||
                // String(item[keys[1] as keyof T]) === String(valueFilter);
            },
        );

    }

    return list;
};

export default filteringTableList;
